import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const ServicesPageComponent = () => {
  const { serviceId } = useParams(); // Get serviceId from the route
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const response = await fetch("/data/pages/services.data.json");
        if (!response.ok) {
          throw new Error("Failed to fetch service data");
        }
        const data = await response.json();
        setServiceData(data[serviceId]); // Get the specific service data
      } catch (error) {
        console.error("Error loading service data:", error);
      }
    };
    fetchServiceData();
  }, [serviceId]);

  if (!serviceData) return <div>Loading...</div>;

  return (
    <div className="service-page-container">
      <h1>{serviceData.title}</h1>
      <h2>מה אנחנו מציעים?</h2>
      <p>{serviceData.whatWeOffer}</p>
      <h2>למה זה חשוב?</h2>
      <p>{serviceData.whyImportant}</p>
      <h2>למה לבחור בנו?</h2>
      <ul>
        {serviceData.whyChooseUs.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  );
};

export default ServicesPageComponent;
