import { useState, useLayoutEffect, useEffect } from "react";

const useMobileScreen = (mobileMaxWidth = 768) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const [windowSize, setWindowSize] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerWidth <= mobileMaxWidth) setIsMobileScreen(true);
    else setIsMobileScreen(false);
  }, [windowSize]);

  return isMobileScreen;
};

export default useMobileScreen;
