import { useState } from "react";
import contact1 from "../../assets/images/home/contact1.png";
import contact2 from "../../assets/images/home/contact2.png";
import { fastContactUs } from "../../models/contact/fastContactUs.model";
import validationHelper from "../../utils/helpers/validation.helper";
import notificationService from "../../services/notifications/notification.service";
import alertsHelper from "../../utils/helpers/alert.helpers";

const HomeContactUsComponent = () => {
  const [contactData, setContactData] = useState(fastContactUs);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!contactData.name) {
      newErrors.name = "יש להזין שם מלא";
    }
    if (!validationHelper.isValidIsraeliPhone(contactData.phone)) {
      newErrors.phone = "יש להזין מספר טלפון תקין";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactData({ ...contactData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await notificationService.sendFastContactUsNotification(contactData);
      if (response.status === 200) {
        alertsHelper.success("הבקשה נשלחה בהצלחה! ניצור איתך קשר בקרוב.");
        setContactData(fastContactUs); // Reset the form
        setErrors({}); // Clear errors
      } else {
        alertsHelper.error("אירעה שגיאה בעת שליחת הבקשה. אנא נסה שוב מאוחר יותר.");
      }
    } catch (error) {
      console.error("Error sending contact request:", error);
      alertsHelper.error("אירעה שגיאה בעת שליחת הבקשה. אנא נסה שוב מאוחר יותר.");
    }
  };

  return (
    <div className="home-contact-us-container">
      <div className="contact-us-container">
        <div className="decor-element-background">
          <img src={contact2} alt="" />
        </div>

        <div className="content-container">
          <h1>צרו איתנו קשר</h1>
          <p>צוות המומחים שלנו ישמח לשמוע על הצרכים שלכם ולעזור לכם לבנות את הפתרון הדיגיטלי המושלם עבור העסק שלכם.</p>
        </div>

        <div className="form-container">
          <div className="input-container">
            <input type="name" name="name" placeholder="הקלד שם מלא" value={contactData.name} onChange={handleInputChange} />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="input-container">
            <input type="text" name="phone" placeholder="הקלד מספר טלפון נייד" value={contactData.phone} onChange={handleInputChange} />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <button className="main-button" onClick={handleSubmit}>
            שלח
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeContactUsComponent;
