import { useEffect, useState } from "react";

export const WhoWeArePageComponent = () => {
  const [content, setContent] = useState();
  console.log("🚀 ~ WhoWeArePageComponent ~ content:", content);

  useEffect(() => {
    const fetchNavigationData = async () => {
      try {
        const response = await fetch("/data/pages/whoWeAre.data.json");
        if (!response.ok) {
          throw new Error("Failed to fetch navigation data");
        }
        const data = await response.json();
        setContent(data);
      } catch (error) {
        console.error("Error loading navigation data:", error);
      }
    };
    fetchNavigationData();
  }, []);

  if (!content) return <div>Loading...</div>;
  
  return (
    <div className="about-us-container">
      <h1>{content.title}</h1>
      <p>{content.intro}</p>
      <h2>השירותים שלנו</h2>
      <ul>
        {content.services.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
      </ul>
      <h2>על הצוות שלנו</h2>
      <p>{content.team}</p>
      <h2>הערכים שלנו</h2>
      <ul>
        {content.values.map((value, index) => (
          <li key={index}>{value}</li>
        ))}
      </ul>
      <h2>המטרה שלנו</h2>
      <p>{content.mission}</p>
      <p>{content.closing}</p>
    </div>
  );
};

export default WhoWeArePageComponent;
