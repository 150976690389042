import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useMobileScreen from "../../hooks/useMobileScreen";

export const HeaderContentComponent = ({props}) => {
  const {toggleOpenNavMenu} = props;
  const isMobileScreen = useMobileScreen();
  const [routesItems, setRoutesItems] = useState([]);
  const [expandRoute, setExpandRoute] = useState();

  useEffect(() => {
    const fetchNavigationData = async () => {
      try {
        const response = await fetch("/data/header/routes.json");
        if (!response.ok) {
          throw new Error("Failed to fetch navigation data");
        }
        const data = await response.json();
        setRoutesItems(data);
      } catch (error) {
        console.error("Error loading navigation data:", error);
      }
    };
    fetchNavigationData();
  }, []);

  const toggeleSubRoute = (route) => {
    if (!isMobileScreen) return;
    if (!route.subRoutes || route.subRoutes.length <= 0) return;
    setExpandRoute(expandRoute ? null : route.id);
  };

  const renderSubRoutesItems = (route) => {
    return route.subRoutes.map((subRoute) => {
      return (
        <Link
          to={`${subRoute.route}`}
          className="subRouteContainer"
          onClick={() => {
            toggleOpenNavMenu();
          }}
        >
          {subRoute.title}
        </Link>
      );
    });
  };

  const showSubRoutesItems = (route) => {
    if (!route.subRoutes || route.subRoutes.length <= 0) return false;
    if (!isMobileScreen) return true;
    if (expandRoute === route.id) return true;
  };

  const renderRoutesItems = (route) => {
    return (
      <div className="routeContainer">
        <Link
          to={`${route.route ? route.route : ""}`}
          onClick={() => {
            toggeleSubRoute(route);
          }}
        >
          <span>{route.title}</span>
          <span>{getExpendMoreIconElement(route)}</span>
        </Link>
        {showSubRoutesItems(route) && <div className="subRoutesContainer">{renderSubRoutesItems(route)}</div>}
      </div>
    );
  };

  const getExpendMoreIconElement = (route) => {
    if (!isMobileScreen) return null;
    if (!route.subRoutes || route.subRoutes.length <= 0) return;
    if (expandRoute === route.id) return <i className="fa-solid fa-caret-up"></i>;
    else return <i className="fa-solid fa-caret-down"></i>;
  };

  return <div className="headerContentContainer">{routesItems.map((route) => renderRoutesItems(route))}</div>;
};
