export const isValidIsraeliPhone = (phone) => {
    const phoneRegex = /^05\d(-?\d{7})$/;
    return phoneRegex.test(phone);
  };
  

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validationHelper = { isValidIsraeliPhone, isValidEmail };
export default validationHelper;
