import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";

import logo1 from "../../assets/images/CusomersLogos/AlpinaLogo.png";
import logo2 from "../../assets/images/CusomersLogos/CristalinoLogo.png";
import logo3 from "../../assets/images/CusomersLogos/GoldenLightLogo.svg";
import logo4 from "../../assets/images/CusomersLogos/LivingMemoriesLogo.png";
import logo5 from "../../assets/images/CusomersLogos/ResultLogo.png";

import "../../styles/components/homrPage/_homeClientsComponent.scss";

export const HomeClientsComponent = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5];

  return (
    <div className="homeClientsContainer">
      <h2>בין לקוחותינו</h2>
      <div style={{ width: "80%", margin: "auto" }}>
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
        >
          {logos.map((logo, index) => (
            <SwiperSlide key={index} className="logo-slide">
              <div className="logo-wrapper">
                <img src={logo} alt={`Customer Logo ${index + 1}`} className="logo-image" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeClientsComponent;
