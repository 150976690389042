import { useEffect, useState } from "react";
import logo from "../assets/images/logo/vertical-logo.svg";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  const [routesItems, setRoutesItems] = useState([]);

  useEffect(() => {
    const fetchNavigationData = async () => {
      try {
        const response = await fetch("/data/header/routes.json");
        if (!response.ok) {
          throw new Error("Failed to fetch navigation data");
        }
        const data = await response.json();
        setRoutesItems(data);
      } catch (error) {
        console.error("Error loading navigation data:", error);
      }
    };
    fetchNavigationData();
  }, []);

  const renderRoutesItems = (route) => {
    if (route.id === "contact") return;

    return (
      <div className="routeContainer">
        <Link to={`${route.route ? route.route : ""}`} className="title">
          {route.title}
        </Link>
        {route.subRoutes && route.subRoutes.length > 0 && <div className="subRoutesContainer">{renderSubRoutesItems(route)}</div>}
      </div>
    );
  };

  const renderSubRoutesItems = (route) => {
    return route.subRoutes.map((subRoute) => {
      return <Link to={`${subRoute.route}`} className="subTitle">{subRoute.title}</Link>;
    });
  };

  const getContactDetailsElement = () => {
    return (
      <div className="contactDetailsContainer">
        <div className="contactDetails">
          <i className="fa-solid fa-envelope"></i>
          <span>info@codeil.org</span>
        </div>
        <div className="contactDetails">
          <i className="fa-solid fa-phone"></i>
          <span>050-2215233</span>
        </div>
      </div>
    );
  };

  return (
    <footer>
      {/* <div className="footerContentContainer"> */}
        <div className="detailsContainer">
          <div className="logoContainer">
            <img src={logo} alt="CodeIL logo" />
          </div>
        {getContactDetailsElement()}
        </div>

      {/* </div> */}
      <div className="termsContainer">
        <span>© 2024 CodeIL. All rights reserved</span>
      </div>
    </footer>
  );
};

export default FooterComponent;
