import React, { useEffect, useState } from "react";

import useMobileScreen from "../hooks/useMobileScreen";
import HeaderLogoComponent from "./header/HeaderLogoComponent";
import { HeaderContentComponent } from "./header/HeaderContentComponent";

const HeaderComponent = ({}) => {
  const isMobileScreen = useMobileScreen();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleOpenNavMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHamburgerMenuClicked = () => {
    toggleOpenNavMenu(!isMenuOpen);
  };

  const getHamburgerMenuElement = () => {
    const element = (
      <div className="hamburgerMenu" onClick={handleHamburgerMenuClicked}>
        <i className="fa-solid fa-bars"></i>
      </div>
    );
    return isMobileScreen ? element : null;
  };

  const showHeaderContentContainer = () => {
    if (isMobileScreen == false) return true;
    return isMenuOpen;
  };

  const getHeaderContentContainerElement = () => {
    if (!showHeaderContentContainer()) return null;
    const headerContentProps = { toggleOpenNavMenu };

    return <HeaderContentComponent props={headerContentProps} />;
  };

  const getActionContainerElement = () => {
    return (
      <div className="actionsContainer">
        <button>צור קשר</button>
      </div>
    );
  };
  return (
    <header className="headerContainer">
      {getHamburgerMenuElement()}
      <HeaderLogoComponent />
      {getHeaderContentContainerElement()}
      {/* {getActionContainerElement()} */}
    </header>
  );
};

export default HeaderComponent;
