import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import { useEffect, useLayoutEffect, useState } from "react";
import WhoWeArePageComponent from "./pages/WhoWeArePage";
import ServicesPageComponent from "./pages/ServicesPageComponent";
import BlogPageComponent from "./pages/BlogPageComponent";

function App() {
  const mobileMaxWidth = 768;
  const [windowSize, setWindowSize] = useState();
  const [isMobileScreen, setIsMobileScreen] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerWidth <= mobileMaxWidth) setIsMobileScreen(true);
    else setIsMobileScreen(false);
  }, [windowSize]);

  const toggleOpenNavMenu = (open) => {
    setIsMenuOpen(open);
  };

  return (
    <div className="page-container">
      <Router>
        <HeaderComponent />
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<WhoWeArePageComponent />} />
            <Route path="/services/:serviceId" element={<ServicesPageComponent />} />
            <Route path="/blog/:blogId" element={<BlogPageComponent />} />
          </Routes>
        </div>
        <FooterComponent />
      </Router>
    </div>

    // <div className="app-caontainer">

    //   <Router>
    //     <HeaderComponent isMobileScreen={isMobileScreen} isMenuOpen={isMenuOpen} toggleOpenNavMenu={toggleOpenNavMenu} />
    //     <Routes>
    //       <Route path="/" element={<HomePage />} />
    //       <Route path="/about" element={<WhoWeArePageComponent />} />
    //       <Route path="/services/:serviceId" element={<ServicesPageComponent />} />
    //     </Routes>
    //     <FooterComponent />
    //   </Router>
    // </div>
  );
}

export default App;

// {isMenuOpen && (
//   <div
//     className="main-screen"
//     onClick={() => {
//       toggleOpenNavMenu(!isMenuOpen);
//     }}
//   ></div>
// )}
