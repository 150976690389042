import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const BlogPageComponent = () => {
  const { blogId } = useParams(); // Get blogId from the route
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch("/data/pages/blog.data.json");
        if (!response.ok) {
          throw new Error("Failed to fetch blog data");
        }
        const data = await response.json();
        setArticle(data[blogId]); // Get the specific article data
      } catch (error) {
        console.error("Error loading blog data:", error);
      }
    };
    fetchBlogData();
  }, [blogId]);

  if (!article) return <div>Loading...</div>;

  return (
    <div className="blog-article-container">
      <h1>{article.title}</h1>
      {article.sections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogPageComponent;
