import { useNavigate } from "react-router-dom";
import logo from "../../../src/assets/images/logo/logo.svg";

export const HeaderLogoComponent = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div className="logoContainer" onClick={handleLogoClick}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default HeaderLogoComponent;
