import Swal from "sweetalert2";

const alertsHelper = {
  success: (title = "", text = "") => {
    Swal.fire({
      title,
      text,
      icon: "success",
      confirmButtonText: "OK",
      timer: 3000,
      timerProgressBar: true,
    });
  },

  error: (title = "", text = "") => {
    Swal.fire({
      title,
      text,
      icon: "error",
      confirmButtonText: "OK",
    });
  },

  warning: (title = "", text = "") => {
    Swal.fire({
      title,
      text,
      icon: "warning",
      confirmButtonText: "OK",
    });
  },

  info: (title = "", text = "") => {
    Swal.fire({
      title,
      text,
      icon: "info",
      confirmButtonText: "OK",
    });
  },

  confirm: async (title = "", text = "") => {
    const result = await Swal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    return result.isConfirmed;
  },
};

export default alertsHelper;
